"use client";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import Link from "next/link";
import React, { useEffect, useState } from "react";

const CheapFlights = () => {
  const axios = globalAxiosURL();
  const [flights, setFlights] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/flights`)
      .then((response) => {
        setFlights(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setIsLoading(false);
  }, [axios]);

  return (
    <div data-aos="fade-up">
      <h3 className="font-semibold text-2xl lg:text-[28px]">
        Cheap Flights to Popular Locations
      </h3>
      <h4 className="text-lg lg:text-[22px] font-medium mt-5 mb-10">
        There's a better way, which we know.
      </h4>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-10 text-center mb-10">
        {flights.map((flight) => (
          <Link
            key={flight._id}
            href={`/cheap-flights/${flight.route.destination.city}`}
          >
            <h3 className="hover:underline text-sm md:text-base">
              Flight to {flight.route.destination.city} (
              {flight.route.destination.airportCode})
            </h3>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CheapFlights;
